<template>
    <div>
        <div class="table_content">

            <commonTitle></commonTitle>
            <div class="search">
                <el-form ref="form" :model="form" label-width="80px" :inline="true">
                    <el-form-item label="企业名称:">
                        <el-input v-model="form.name" placeholder="请输入企业名称" size="mini"></el-input>
                    </el-form-item>
                    <button style="cursor: pointer;" @click.prevent="common()">搜索</button>
                    <button style="cursor: pointer;" @click.prevent="createOrder" v-show="userType">新增订单</button>
                </el-form>
            </div>
            <el-card>
                <el-table :data="tableData"
                          style="width:100%"
                          
                          :default-sort="{prop: 'settingTime', order: 'descending'}"
                          :stripe="true"
                          v-loading="loading"
                          element-loading-text="拼命加载中"
                >
                    <el-table-column prop="villageName" label="小区名称" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="towerNum" label="楼幢号" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="unitName" label="单元名称" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="houseNum" label="户室号" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="ownerName" label="业主姓名" show-overflow-tooltip>
                    </el-table-column>

                    <!--                <el-table-column prop="orderNo" label="订单号" show-overflow-tooltip>-->
                    <!--                </el-table-column>-->

                    <el-table-column prop="orderType" label="订单类型" show-overflow-tooltip
                                     :formatter="farmatterOrderType">
                    </el-table-column>
                    <el-table-column prop="orderAmt" label="订单金额" show-overflow-tooltip>
                    </el-table-column>
                    <!--                <el-table-column prop="acctName" label="房管局专户户名" width="120">-->
                    <!--                </el-table-column>-->
                    <el-table-column prop="creatTime" label="订单生成时间" width="120" show-overflow-tooltip
                                     :formatter="formatTime">
                    </el-table-column>
                    <!--                <el-table-column prop="legalPerson" label="企业法人" show-overflow-tooltip>-->
                    <!--                </el-table-column>-->
                    <!--                <el-table-column prop="orgName" label="企业名称" show-overflow-tooltip>-->
                    <!--                </el-table-column>-->
                    <el-table-column prop="orgStat" label="订单状态" width="120" :formatter="formatStat">
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click.native.prevent="detail(scope.row)" type="text"
                                       size="mini">
                                查看详情
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        background
                        style="margin-top: 50px"
                        layout="prev, pager, next"
                        :page-size="15"
                        @current-change="handleCurrentChange"
                        :total="totalNum">
                </el-pagination>
            </el-card>
        </div>
    </div>
</template>

<script>
    import commonTitle from "../table/commonTitle";

    export default {
        name: "order",
        components: {
            commonTitle
        },
        data() {
            return {
                url: this.$Config.base_server,
                tableData: [],//表格数据
                totalNum: 0,
                loading: false,
                form: {
                    name: "",
                    region: '',
                },
                userType: '', //记录用户状态
                data: {
                    limit: 15,
                    access_token: localStorage.getItem("token"),
                }
            }
        }, mounted() {
            //获取用户状态 业主登录时 隐藏新增订单按钮
            let type = JSON.parse(localStorage.getItem("user")).type;
            if (type == 3) {
                //业主
                this.userType = false;
            } else {
                this.userType = true;
            }
            this.common();
        }, methods: {
            common(currentPage = 0) {
                //初始化数据
                const _this = this;
                _this.loading = true;

                _this.data.page = currentPage;
                _this.data.orgName = _this.form.name;
                //查询当前登录者的订单信息
                $.ajax({
                    url: _this.url + "/api-public/busiorder/findAllBusiOrder",
                    type: 'GET',
                    data: _this.data,
                    contentType: 'application/json',
                    success: function (res) {
                        _this.loading = false;
                        _this.tableData = res.data;
                        _this.totalNum = res.count;
                    },
                    error: function (res) {
                        _this.loading = false;
                    }
                });
            }, handleSizeChange(val) {
                console.log('每页${val}条');
                this.pagesize = val;
            },
            handleCurrentChange(val) {
                console.log('当前页' + val);
                // this.currpage = val;
                this.common(val);
            }, formatTime(row) {
                //格式化时间
                let date = new Date(row.creatTime);
                let month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
                let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
                let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
                let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
                let second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
                return date.getFullYear() + "-" + month + "-" + day + " " + hour + ":" + minutes + ":" + second;
            }, detail(row) {
                //查看详情按钮
                this.$router.push('/orderDetail');
                //存储订单编号
                localStorage.setItem("orderNo", row.orderNo);
            }, createOrder() {
                //生成订单按钮
                this.$router.push({"name": "checkHouse"});
            }, formatStat(row) {
                //格式化订单状态
                if (row.orgStat == 1) {
                    return '生成';
                } else if (row.orgStat == 2) {
                    return '可缴费';
                } else if (row.orgStat == 3) {
                    return '已查询';
                } else if (row.orgStat == 4) {
                    return '已消费';
                } else if (row.orgStat == 5) {
                    return '作废';
                } else if (row.orgStat == 6) {
                    return '已分摊';
                }
            }, farmatterOrderType(row) {
                if (row.orderType == 1) {
                    return '开发商';
                }
                if (row.orderType == 0) {
                    return '物业';
                }
                if (row.orderType == 2) {
                    return '业主委员会';
                }
                if (row.orderType == 3) {
                    return '业主';
                }
            }
        }
    }
</script>

<style scoped>

</style>